import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AccountUtility from "../../../../api/AccountUtility";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import SelectField from "../../../../components/common/inputs/SelectField";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import {
    BpieFilterVisibilityRules,
    BpieSearchCriteria
} from "../../../../components/common/searchCriterias/BpieSearchCriteria";
import * as apiForLocalStateActions from "../../../../actions/apiForLocalStateActions";
import * as accountActions from "../../../../actions/accountActions";
import * as layoutActions from "../../../../actions/layoutActions";
import * as searchCriteriaActions from '../../../../actions/searchCriteriaActions';
import {
    createListItem,
    isArrayNullOrEmpty,
    isNullOrUndefined,
} from "../../../../components/common/commonUtilities";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import {BpieAdminNavigation} from "../../BpieAdminNavigation";
import {dbpieAssessmentStatusList} from "../../bpieConstants";
import {BpieDistrictNavigation} from "../../BpieDistrictNavigation";
import {BpieFilter} from "../../BpieFilter";
import BpieApi from "../../bpieApi";
import {
    createFinFacilitatorDistrictOptions,
    isFinFacilitator
} from "../../bpieUtilities";
import {DbpieSearchResults} from "./DbpieSearchResults";
import * as bpieLocations from "../../bpieLocations";

const DbpieSearchContainer = ({
                                  actions,
                                  allDistricts,
                                  allDistrictsLoaded,
                                  currentLocation,
                                  dateWindowsAnnualList: dateWindows,
                                  finFacilitatorDistricts,
                                  history,
                                  searchCriteria
                              }) => {
    const [newDistrictId, setNewDistrictId] = useState("");
    const [summary, setSummary] = useState(null);
    const [finUsers, setFinUsers] = useState(null);
    const [dateWindowUpdated, setDateWindowUpdated] = useState("");
    const [districtUpdated, setDistrictUpdated] = useState("");

    const isDistrictUser = AccountUtility.isDistrictUser();
    const userDetails = AccountUtility.getUserDetails();
    const readOnly = isDistrictUser || !allow(BpiePolicy, policyEvents.districtBpieAssessments.MODIFY);
    const finFacilitator = isFinFacilitator(finFacilitatorDistricts);

    const handleClickDistrict = (event, districtId, districtBpieId) => {
        event.preventDefault();

        const path = isDistrictUser ? bpieLocations.DISTRICT_DBPIE_INFO : bpieLocations.STATE_DBPIE_INFO;
        history.push(path.getUrl(searchCriteria.dateWindowId, districtId, districtBpieId));
    }

    const handleSearch = async (criteria, gotoFirstPage = true) => {
        if (isDistrictUser)
            criteria.district = userDetails.Institution;

        if(!isValidSearch(criteria)) {
            NotifyUser.Warning("Please select either a date window or a district.");
            return;
        }

        const dateWindowId = criteria.dateWindowId || emptyGuid;
        const districtId = !criteria.district ? emptyGuid : allDistricts.find(f => f.text === criteria.district).value;

        const result = await actions.executeApi(BpieApi.getDistrictBpiesSummary, [dateWindowId, districtId]);
        setSummary(result);
        await updateSearchResults(criteria, result, gotoFirstPage);
    };

    const updateSearchResults = async (criteria, currentSummary, gotoFirstPage = true) => {
        if (gotoFirstPage)
            criteria.First();

        setNewDistrictValueFromSearchCriteria(criteria.district);

        actions.saveSearchCriteria(criteria);
    };

    const isValidSearch = (criteria) => {
        return criteria.dateWindowId || criteria.district;
    }

    const setNewDistrictValueFromSearchCriteria = (districtText) => {
        if(!isNullOrUndefined(districtText)) {
            if(finFacilitator) {
                const district = finFacilitatorDistricts.find(f => f.name === districtText);

                if(district && district.districtId !== newDistrictId)
                    setNewDistrictId(district.districtId);
            }
            else {
                const district = allDistricts.find(f => f.text === districtText);

                if (district && district.value  !== newDistrictId)
                    setNewDistrictId(district.value);
            }
        }
    }

    const autoSearch = async (criteria, gotoFirstPage = true) => {
        if(isDistrictUser || isValidSearch(criteria))
            await handleSearch(criteria, gotoFirstPage);
        else {
            setSummary(null);
            await updateSearchResults(criteria, summary);
        }
    }

    const handleSearchFiltersChange = async (criteria, reloadPage) => {
        const isDistrictUpdated = districtUpdated !== criteria.district;
        const isDateWindowUpdated = dateWindowUpdated !== criteria.dateWindowId;
        if(reloadPage || isDistrictUpdated || isDateWindowUpdated) {
            if(isDistrictUpdated)
                setDateWindowUpdated(criteria.district);

            if(isDateWindowUpdated)
                setDateWindowUpdated(criteria.dateWindowId);

            await autoSearch(criteria);
        }
        else
            await updateSearchResults(criteria, summary);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const initializeData = async (criteria) => {
        actions.saveSearchCriteria(criteria);

        await autoSearch(criteria, false);
    };

    const initializeFinUsers = async () => {
        if(isDistrictUser) {
            setFinUsers([]);
            return;
        }

        const results = await actions.executeApi(BpieApi.getFinUsers);
        setFinUsers(results);
    };

    const newDbpiesOnlyList = () => {
        if(!summary)
            return [];

        return finFacilitator
            ? createFinFacilitatorDistrictOptions(finFacilitatorDistricts.filter(f => !summary.find(s => s.districtId === f.districtId)))
            : allDistricts.filter(f => !summary.find(s => s.districtId === f.value));
    };

    useEffect(() => {
        if (!allDistrictsLoaded || isNullOrUndefined(finUsers)) return;

        let criteria = {...searchCriteria};
        if (!isDistrictUser)
            criteria.setDistrictList(allDistricts);
        else {
            criteria.district = userDetails.Institution;
            criteria.setDistrictList([]);
        }

        criteria.allowAllDateWindows = true;
        criteria.statusSingleOptions = dbpieAssessmentStatusList;
        criteria.textSearchLabel = "District Name or Number";
        criteria.setVisibilityRules(BpieFilterVisibilityRules.DbpieSearch);
        criteria.finUsers = isDistrictUser ? null : finUsers.map(finUser => createListItem(finUser.userId, finUser.fullName));
        initializeData(criteria);

        setNewDistrictValueFromSearchCriteria(criteria.district);
    }, [allDistrictsLoaded, finUsers]);

    useEffect(() => {
        if (AccountUtility.isBpieSchoolAdmin())
            history.push(bpieLocations.BPIE_LANDING.getUrl());

        initializeFinUsers();

        actions.updatePageTitle(`DBPIE Assessments`);
    }, []);

    if (!searchCriteria || !allDistricts || !searchCriteria.areRulesCurrent(BpieFilterVisibilityRules.DbpieSearch) || !finUsers)
        return null;

    return (
        <>
            <BpieFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            {
                !readOnly &&
                <GridRow align={`right`}>
                    <GridColumn medium={"6"}>
                        <SelectField
                            defaultOption={`Select a district`}
                            includeDefaultOption={true}
                            label={`New DBPIE Assessment for:`}
                            medium={6}
                            name="newDistrictId"
                            onChange={(event) => setNewDistrictId(event.target.value)}
                            options={newDbpiesOnlyList()}
                            showLabel
                            labelClass={`new-dbpie-label`}
                            value={newDistrictId}
                            wrapperClass={`align-elements-right`}
                        />
                    </GridColumn>
                    <GridColumn medium={"3"}>
                        <Button
                            btnClass={`align-elements-right`}
                            buttonType={ButtonTypes.ADD}
                            disabled={!newDistrictId || !searchCriteria.dateWindowId}
                            label={`Add DBPIE Assessment`}
                            name={`btnAddDbpie`}
                            onClick={(event) => handleClickDistrict(event, newDistrictId, emptyGuid)}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>
            }
            {
                !isNullOrUndefined(summary) &&
                <DbpieSearchResults
                    actions={actions}
                    finFacilitatorDistricts={finFacilitatorDistricts}
                    handleClickDistrict={handleClickDistrict}
                    handleClickOrder={handleClickOrder}
                    searchCriteria={searchCriteria}
                    summary={searchCriteria.applyFiltersAndOrder(summary, finUsers)}
                />
            }
            {
                isNullOrUndefined(summary) &&
                <>
                    <FlexSpacer/>
                    <div className={`search-results-warning-container`}>
                        <p className={`search-results-warning`}>Please choose a date window or district.</p>
                    </div>
                </>
            }
            <FlexSpacer/>
            {
                !isDistrictUser &&
                <BpieAdminNavigation currentLocation={currentLocation} history={history}/>
            }

            {
                isDistrictUser &&
                <BpieDistrictNavigation currentLocation={currentLocation} history={history}/>
            }
        </>
    );
};

const mapStateToProps = (state, props) => {
    const searchCriteria = state.searchCriteria.BpieSearchCriteria || new BpieSearchCriteria();

    let allDistricts = [...state.sharedData.institutionsWithStateAgencies].map(ins => {
        return {value: ins.id, text: ins.name};
    });

    const currentLocation = props.location.pathname;
    const allDistrictsLoaded = !isArrayNullOrEmpty(allDistricts);

    return {
        allDistricts,
        allDistrictsLoaded,
        currentLocation,
        finFacilitatorDistricts: state.bpie.finFacilitatorDistricts,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        accountActions,
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DbpieSearchContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
    allDistrictsLoaded: PropTypes.bool.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    finFacilitatorDistricts: PropTypes.array,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DbpieSearchContainer);

