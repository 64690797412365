import React from 'react';
import PropTypes from 'prop-types';
import InputLayout from "./InputLayout";
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import {isString} from "../commonUtilities";

const DisplayField = ({
	                      children="",
						  columnsLarge,
	                      columnsMedium,
	                      displayClass,
						  helpText,
						  isInline,
	                      label,
	                      labelClass,
	                      name,
	                      showLabel,
	                      wrapperClass,
                      }) => {

	const isHtml = isString(children);

	if (!showLabel) {
		labelClass += " is-visuallyhidden";
	}
	if(isInline){
		labelClass += " columns shrink row__space-right";
	}

	return (
		<InputLayout
			helpText={helpText}
			label={label}
			labelClass={labelClass}
			large={columnsLarge}
			medium={columnsMedium}
			name={name}
			showLabel={showLabel}
			wrapperClass={wrapperClass}
			inputClass={isInline ? "columns" : ""}
		>
			{
				isHtml &&
				<HtmlDisplayBlock
					className={displayClass}
					name={name}
					html={children}
				/>
			}
			{
				!isHtml &&
				<div id={name} className={displayClass}>{children}</div>
			}
		</InputLayout>
	);
};

DisplayField.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.bool,
		PropTypes.object,
		PropTypes.string
	]).isRequired,
	columnsLarge: PropTypes.number,
	columnsMedium: PropTypes.number,
	displayClass: PropTypes.string,
	helpText: PropTypes.string,
	isInline: PropTypes.bool,
	label: PropTypes.string.isRequired,
	labelClass: PropTypes.string,
	name: PropTypes.string.isRequired,
	showLabel: PropTypes.bool,
	wrapperClass: PropTypes.string,
};

export default DisplayField;