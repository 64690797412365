import {
    createDescriptionObjectForList, createListFromObject, createListItem, generateArrayFromObjectValues,
} from "../../components/common/commonUtilities";
import {createYesNoList} from "../../components/common/inputs/inputUtility";
import {createActivityCategoryObject} from "./ptsObjectFactory";

export const API_MESSAGES = {
    ADMIN: {
        GMS_USERS: {
            saved: `The GMS user was successfully saved.`,
            deleted: `The GMS user was successfully deleted.`,
        },
        PTS_SETTINGS: {
            saved: `The Fiscal Agent Access settings were successfully saved.`
        },
        PROGRAMS: {
            saved: `The program was successfully saved.`,
            deleted: `The program was successfully deleted.`,
            FISCAL_AGENTS_USERS: {
                saved: `The associated fiscal agents and users were successfully saved.`,
            },
            PROGRAM_USER: {
                saved: `The user was successfully saved.`,
            }
        },
        PROJECT: {
            saved: `The project was successfully saved.`,
            deleted: `The project was successfully deleted.`,
        }
    },
    IMPLEMENTATIONS: {
        saved: `The implementation was successfully saved.`,
        deleted: `The implementation was successfully deleted.`
    },
    QUARTERLY_REPORTS: {
        submitted: `The quarterly deliverables were successfully submitted.`,
        approved: `The quarterly report was successfully approved.`,
        revisionRequest: `The quarterly report was successfully revision requested.`,
        reset: `The quarterly deliverables were successfully reset.`,
        commentsSaved: `The comment was successfully saved.`,
    },
};

export const PTS_ROLES = {
    grantsManagementUser: createDescriptionObjectForList(1, "Grants Management User"),
    liaisonUser: createDescriptionObjectForList(2, "BEESS Liaison"),
    projectManager: createDescriptionObjectForList(3, "Project Manager")
};

export const PTS_ROLES_LIST = createListFromObject(PTS_ROLES);

export const DISCRETIONARY_PROJECT_STATUS = {
    quarterlyReportInProgress: createDescriptionObjectForList(1, "In Progress"),
    quarterlyReportSubmitted: createDescriptionObjectForList(2, "Submitted"),
    quarterlyReportNeedsRevision: createDescriptionObjectForList(3, "Needs Revision"),
    quarterlyReportApproved: createDescriptionObjectForList(4, "Approved"),
    completed: createDescriptionObjectForList(20, "Completed")
};

export const DISCRETIONARY_PROJECT_STATUS_LIST = generateArrayFromObjectValues(DISCRETIONARY_PROJECT_STATUS).map((type) => createListItem(type.description));

export const DISCRETIONARY_PROJECT_QUARTERS = {
    q1: createDescriptionObjectForList(1, "Quarter 1"),
    q2: createDescriptionObjectForList(2, "Quarter 2"),
    q3: createDescriptionObjectForList(3, "Quarter 3"),
    q4: createDescriptionObjectForList(4, "Quarter 4")
};

export const DISCRETIONARY_PROJECT_QUARTERS_LIST = createListFromObject(DISCRETIONARY_PROJECT_QUARTERS);

export const QUARTERLY_REPORT_STATUS = {
    notStarted: createDescriptionObjectForList(1, "Not Started"),
    inProgress: createDescriptionObjectForList(2, "In Progress"),
    submitted: createDescriptionObjectForList(3, "Submitted"),
    approved: createDescriptionObjectForList(4, "Approved"),
    needsRevision: createDescriptionObjectForList(5, "Needs Revision")
};

export const ACTIVITY_CATEGORIES = {
    training: createActivityCategoryObject(1, "Training", "training", "trainingActivities"),
    product: createActivityCategoryObject(2, "Product", "product", "productActivities"),
    serviceDelivery: createActivityCategoryObject(3, "Service Delivery", "serviceDelivery", "serviceDeliveryActivities")
};

export const ACTIVITY_CATEGORIES_LIST = generateArrayFromObjectValues(ACTIVITY_CATEGORIES);

const PRODUCT_DISSEMINATION_METHODS = {
    electronic: createDescriptionObjectForList("Electronic", "Electronic"),
    hardCopy: createDescriptionObjectForList("Hard Copy", "Hard Copy")
}

export const PRODUCT_DISSEMINATION_METHODS_LIST = createListFromObject(PRODUCT_DISSEMINATION_METHODS);

const AUDIENCES = {
    agencyPersonnel: createDescriptionObjectForList("Agency Personnel", "Agency Personnel"),
    collegeUniversityPersonnel: createDescriptionObjectForList("College/University Personnel", "College/University Personnel"),
    discretionaryProjectPersonnel: createDescriptionObjectForList("IDEA Funded State Project Personnel", "IDEA Funded State Project Personnel"),
    districtPersonnelAdministrative: createDescriptionObjectForList("District Personnel - Administrative", "District Personnel - Administrative"),
    districtPersonnelInstructional: createDescriptionObjectForList("District Personnel - Instructional", "District Personnel - Instructional"),
    districtPersonnelNonInstructional: createDescriptionObjectForList("District Personnel - Non-Instructional", "District Personnel - Non-Instructional"),
    parentOfChildBirthPrek: createDescriptionObjectForList("Parent of Child Birth-PreK", "Parent of Child Birth-PreK"),
    parentOfChildK12: createDescriptionObjectForList("Parent of Child K-12", "Parent of Child K-12")
};

export const AUDIENCES_LIST = createListFromObject(AUDIENCES);

export const AUDIENCES_WITH_EXTRA_DETAILS = [AUDIENCES.districtPersonnelAdministrative, AUDIENCES.districtPersonnelInstructional, AUDIENCES.districtPersonnelNonInstructional, AUDIENCES.parentOfChildBirthPrek, AUDIENCES.parentOfChildK12];

const SCHOOL_TYPES = {
    public: "Public",
    private: "Private",
    charter: "Charter",
    homeSchool: "Home School"
};

export const SCHOOL_TYPES_LIST = createListFromObject(SCHOOL_TYPES);

export const RadioButtonYesNoList = createYesNoList();

export const IMPLEMENTATION_DETAILS_INITIAL_VALUE = {
    product: "Why is this product needed?",
    training: "Who requested the training?",
    serviceDelivery: ""
};

export const PTS_REPORT_TYPES = {
    quarterlyActivityReports: "quarterlyActivityReports",
    scheduleOfDeliverables: "scheduleOfDeliverables",
};

export const PTS_REPORT_TYPES_LIST = createListFromObject(PTS_REPORT_TYPES);
