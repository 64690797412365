import React from "react";
import PropTypes from 'prop-types';
import {convertToBoolean, isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DateTimePickerField} from "../../../components/common/inputs";
import {clearInputFormErrorFields, inputFormIsValid} from "../../../components/common/inputs/inputUtility";
import NumberField from "../../../components/common/inputs/NumberField";
import TextArea from "../../../components/common/inputs/TextArea";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {gridConstants} from "../../../constants/inputConstants";
import {PtsDeliverableSummaryAudienceEdit} from "./PtsDeliverableSummaryAudienceEdit";
import {PtsDeliverableSummaryButtons} from "./PtsDeliverableSummaryButtons";
import {DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG} from "./ptsDeliverableSummaryConstants";

export const PtsDeliverableSummaryServiceDeliveryEdit = ({
                                                             disableFields,
                                                             disableExtraAudienceDetails,
                                                             districtsList,
                                                             handleClickCancelEdit,
                                                             handleClickDelete,
                                                             handleClickSave,
                                                             handleDataChanged,
                                                             hasDetails,
                                                             implementationForm,
                                                             implementationStatusDetailsLabel,
                                                             isAdding
                                                         }) => {
    const validateForm = () => {
        clearInputFormErrorFields(implementationForm);

        if (isTrimmedStringEmpty(implementationForm.implementationName.value))
            implementationForm.implementationName.setError("Required.");

        if(!hasDetails) {
            if (isTrimmedStringEmpty(implementationForm.implementationDate.value))
                implementationForm.implementationDate.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.implementationDetails.value))
                implementationForm.implementationDetails.setError("Required.");
        }
        else {
            if (isTrimmedStringEmpty(implementationForm.facilitator.value))
                implementationForm.facilitator.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.timeAndEffort.value))
                implementationForm.timeAndEffort.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.dateOfService.value))
                implementationForm.dateOfService.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.totalNumberOfServiceDeliveryParticipants.value.toString()))
                implementationForm.totalNumberOfServiceDeliveryParticipants.setError("Required.");

            if (isArrayNullOrEmpty(implementationForm.audienceTypes.value))
                implementationForm.audienceTypes.setError("Required.");

            const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);
            if (!disableExtraAudienceDetails && !isIncludeAllDistrictsChecked && isArrayNullOrEmpty(implementationForm.districts.value))
                implementationForm.districts.setError("Required.");

            if (!disableExtraAudienceDetails && isArrayNullOrEmpty(implementationForm.schoolTypes.value))
                implementationForm.schoolTypes.setError("Required.");
        }

        let isValid = inputFormIsValid(implementationForm, DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    return <>
        <TextField label={`Name and Type of Service`} showLabel disabled={disableFields}
                   {...implementationForm.implementationName} />

        <TextArea label={implementationStatusDetailsLabel} showLabel disabled={disableFields}
                  {...implementationForm.implementationDetails} />

        {
            hasDetails &&
            <>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <DateTimePickerField
                            {...implementationForm.dateOfService}
                            disabled={disableFields}
                            label="Date of Service:"
                            showLabel
                        />

                        <TextField label={`Facilitator`} showLabel disabled={disableFields}
                                   {...implementationForm.facilitator} />

                        <TextField label={`Time and Effort`} showLabel disabled={disableFields}
                                   {...implementationForm.timeAndEffort} />

                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            decimalPlaces={0}
                            disabled={disableFields}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Total Number of Participants`} {...implementationForm.totalNumberOfServiceDeliveryParticipants}
                            showLabel
                        />

                        <TextField {...implementationForm.collaborators} label={`Collaborators`} showLabel disabled={disableFields} />

                    </GridColumn>
                </GridRow>

                <PtsDeliverableSummaryAudienceEdit
                    disableExtraAudienceDetails={disableExtraAudienceDetails}
                    disableFields={disableFields}
                    districtsList={districtsList}
                    handleDataChanged={handleDataChanged}
                    implementationForm={implementationForm}
                />
            </>
        }

        <PtsDeliverableSummaryButtons
            disableFields={disableFields}
            handleClickCancelEdit={handleClickCancelEdit}
            handleClickDelete={handleClickDelete}
            handleClickSave={handleClickSave}
            isAdding={isAdding}
            validateForm={validateForm}
        />
    </>;
};

PtsDeliverableSummaryServiceDeliveryEdit.propTypes = {
    disableFields: PropTypes.bool.isRequired,
    disableExtraAudienceDetails: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleClickCancelEdit: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    implementationForm: PropTypes.object.isRequired,
    implementationStatusDetailsLabel: PropTypes.string.isRequired,
    isAdding: PropTypes.bool.isRequired
};