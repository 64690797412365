import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import AccountUtility from "../../../api/AccountUtility";
import {
    isTrimmedStringEmpty,
    setPageTitle
} from "../../../components/common/commonUtilities";
import Button from "../../../components/common/buttons/Button";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import * as LayoutActions from "../../../actions/layoutActions";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getParamsMultiple} from "../../../components/layout/getParams";
import {emptyGuid} from "../../../constants/config";
import {DistrictBpieAssessmentSections} from "../bpieConstants";
import * as bpieLocations from "../bpieLocations";
import {DASHBOARD} from "../../../constants/systemLocations";
import {CanEditDistrictBpie, CanSubmitDistrictBpie} from "../bpieUtilities";
import bpieDistrictApi from "./bpieDistrictApi";
import {createDbpie} from "./bpieDistrictObjectFactory";

const BpieDistrictWrapper = ({
                                 actions,
                                 Component,
                                 currentLocation,
                                 dateWindowId,
                                 districtId,
                                 districtBpieId,
                                 finFacilitatorDistricts,
                                 finFacilitatorDistrictsLoaded,
                                 history,
                                 indicatorNumber,
                                 props
                             }) => {
    const [dataChanged, setDataChanged] = useState(false);
    const [districtBpie, setDistrictBpie] = useState(null);
    const isDistrictUser = AccountUtility.isDistrictUser();

    const districtInfoUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_INFO.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DBPIE_INFO.getUrl(dateWindowId, districtId, districtBpieId);

    const assessmentUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, indicatorNumber).replace(bpieLocations.INDICATOR_NUMBER, 1)
        : bpieLocations.STATE_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, indicatorNumber).replace(bpieLocations.INDICATOR_NUMBER, 1);

    const summaryUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_SUMMARY.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DBPIE_SUMMARY.getUrl(dateWindowId, districtId, districtBpieId);

    const prioritiesUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_PRIORITIES.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DBPIE_PRIORITIES.getUrl(dateWindowId, districtId, districtBpieId);

    const returnDistrictBpieSearchUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_SEARCH.getUrl()
        : bpieLocations.STATE_DBPIE_SEARCH.getUrl();

    const getScrollConfig = () => {
        const scrollConfig = {
            duration: 300,
            delay: 0,
            smooth: true
        };

        return scrollConfig;
    };

    const handleScroll = (containerId) => {
        const scroller = Scroll.scroller;
        window.setTimeout(() => {
            scroller.scrollTo(containerId, getScrollConfig());
        }, 150);
    };

    const handleScrollToTop = () => {
        const animateScroll = Scroll.animateScroll;
        window.setTimeout(() => {
            animateScroll.scrollToTop(getScrollConfig());
        }, 150);
    }

    const handleSelectSection = (event, section, forceReplace = false) => {
        let url = "";
        switch (section) {
            case DistrictBpieAssessmentSections.DistrictInformation:
                url = districtInfoUrl;
                break;
            case DistrictBpieAssessmentSections.Assessment:
                url = summaryUrl;
                break;
        }

        if (url)
            navButtonClick(event, url, forceReplace);
    };

    const getSectionFromCurrentLocation = () => {
        switch (currentLocation) {
            case districtInfoUrl:
                return DistrictBpieAssessmentSections.DistrictInformation;
            case assessmentUrl:
            case summaryUrl:
            case prioritiesUrl:
                return DistrictBpieAssessmentSections.Assessment;
        }

        return "";
    }

    const navButtonClick = (event, location, forceReplace = false) => {
        event.preventDefault();

        if (forceReplace) {
            normalizeUrlAndRedirect(location, true);
            setDataChanged(false);
            return;
        }

        if (location === currentLocation) return;

        if (dataChanged) {
            if (!confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
                return;
        }

        setDataChanged(false);
        normalizeUrlAndRedirect(location);

        handleScrollToTop();
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const normalizeUrlAndRedirect = (location, isReplace = false, dbpieId = null) => {
        const path = location
            .replace(bpieLocations.BPIE_DATE_WINDOW_ID, dateWindowId)
            .replace(bpieLocations.BPIE_DISTRICT_ID, districtId)
            .replace(bpieLocations.DISTRICT_BPIE_ID, dbpieId || districtBpieId)
            .replace(bpieLocations.INDICATOR_NUMBER, 1);
        isReplace ? history.replace(path) : history.push(path);
    };

    const handleUpdateDistrictBpie = (result) => {
        const canEdit = CanEditDistrictBpie(result, finFacilitatorDistricts);
        const canSubmit = CanSubmitDistrictBpie(result, finFacilitatorDistricts);
        setDistrictBpie(createDbpie(result, canEdit, isDistrictUser, canSubmit));
        if(dataChanged)
            setDataChanged(false);
    };

    const handleAssessmentRedirect = (desiredIndicator) => {
        setDistrictBpie(null);
        loadDistrictBpie();

        if (!desiredIndicator)
            handleSummaryRedirect();
        else {
            const newAssessmentUrl = isDistrictUser
                ? bpieLocations.DISTRICT_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, desiredIndicator)
                : bpieLocations.STATE_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, desiredIndicator)
            normalizeUrlAndRedirect(newAssessmentUrl);
            handleScrollToTop();
        }
    };

    const handleSummaryRedirect = () => {
        normalizeUrlAndRedirect(summaryUrl);
    }

    const handlePrioritiesRedirect = () => {
        normalizeUrlAndRedirect(prioritiesUrl);
    }

    const loadDistrictBpie = () => {
        actions.executeApi(bpieDistrictApi.getDistrictBpie, [dateWindowId, districtId, districtBpieId])
            .then((result) => {
                handleUpdateDistrictBpie(result);
                if(districtBpieId === emptyGuid && result.districtBpieId && result.districtBpieId !== emptyGuid)
                    normalizeUrlAndRedirect(bpieLocations.STATE_DBPIE_INFO.path, true, result.districtBpieId);
            });
    }

    useEffect(() => {
        if (AccountUtility.isBpieSchoolAdmin()) {
            history.push(DASHBOARD.path);
            return;
        }

        if(!finFacilitatorDistrictsLoaded)
            return;

        const userDetails = AccountUtility.getUserDetails();
        if (isDistrictUser && districtId !== userDetails.InstitutionId) {
            history.push(bpieLocations.DISTRICT_BPIE_SUMMARY.getUrl());
            return;
        }

        loadDistrictBpie();
    }, [finFacilitatorDistrictsLoaded]);

    useEffect(() => {
        if (!districtBpie)
            return;

        const headerLeft = `DBPIE Assessment`;
        const headerCenter = getSectionFromCurrentLocation();
        const headerRight = districtBpie.districtName;

        actions.updatePageTitle(headerLeft, headerCenter, headerRight);
        setPageTitle(`${headerLeft}${isTrimmedStringEmpty(headerCenter) ? "" : ` - ${headerCenter}`}; ${districtBpie.districtName}`);
    }, [districtBpie, currentLocation]);

    if (!districtBpie)
        return null;

    return (
        <GridRow
            isPageNav
        >
            <GridColumn large="3">
                <GridRow>
                    <GridColumn
                        isPageNavLinks
                        isStickyLinks
                    >
                        <Button
                            btnClass={currentLocation === districtInfoUrl ? "is-active" : ""}
                            onClick={(event) => navButtonClick(event, districtInfoUrl)}
                            label={DistrictBpieAssessmentSections.DistrictInformation}
                            name={districtInfoUrl}
                        />
                        {
                            !!districtBpie.districtBpieId &&
                            <Button
                                btnClass={(currentLocation === assessmentUrl || currentLocation === summaryUrl || currentLocation === prioritiesUrl) ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, districtBpie.responses.length === 0 && districtBpie.isEditable ? assessmentUrl : summaryUrl)}
                                label={DistrictBpieAssessmentSections.Assessment}
                                name={summaryUrl}
                            />
                        }
                        <Button
                            onClick={(event) => navButtonClick(event, returnDistrictBpieSearchUrl)}
                            label={"Return to DBPIE Search"}
                            name={returnDistrictBpieSearchUrl}
                        />
                    </GridColumn>
                </GridRow>
            </GridColumn>
            <GridColumn
                isPageNavContent
                large="9"
            >
                <Component
                    {...props}
                    actions={actions}
                    dataChanged={dataChanged}
                    dateWindowId={dateWindowId}
                    districtBpie={districtBpie}
                    districtBpieId={districtBpieId}
                    districtId={districtId}
                    handleAssessmentRedirect={handleAssessmentRedirect}
                    handleDataChanged={handleDataChanged}
                    handlePrioritiesRedirect={handlePrioritiesRedirect}
                    handleScroll={handleScroll}
                    handleScrollToTop={handleScrollToTop}
                    handleSelectSection={handleSelectSection}
                    handleSummaryRedirect={handleSummaryRedirect}
                    indicatorNumber={indicatorNumber}
                    isDistrictUser={isDistrictUser}
                    isEditable={districtBpie.isEditable}
                    loadDistrictBpie={loadDistrictBpie}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;

    const allRoutes = AccountUtility.isDistrictUser() ? bpieLocations.AllDistrictBpieRoutes : bpieLocations.AllStateBpieRoutes;
    const multipleParams = getParamsMultiple(currentLocation, allRoutes);
    const params = !multipleParams ? {} : multipleParams;

    return {
        ...params,
        Component,
        currentLocation,
        finFacilitatorDistricts: state.bpie.finFacilitatorDistricts,
        finFacilitatorDistrictsLoaded: state.bpie.finFacilitatorDistrictsLoaded,
        props,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

BpieDistrictWrapper.propTypes = {
    actions: PropTypes.object,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    districtBpieId: PropTypes.string,
    finFacilitatorDistricts: PropTypes.array,
    finFacilitatorDistrictsLoaded: PropTypes.bool.isRequired,
    history: PropTypes.object,
    indicatorNumber: PropTypes.string,
    props: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BpieDistrictWrapper);