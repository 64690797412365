import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import GridTable from "../../../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import ResultsPager from "../../../../components/common/pagers/ResultsPager";
import {handleScroll} from "../../../../components/common/scrollUtilities";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {SortType} from "../../../../constants/sharedData/dynamicSorting";
import {CanEditDistrictBpie} from "../../bpieUtilities";

export const DbpieSearchResults = ({
                                       actions,
                                       finFacilitatorDistricts,
                                       handleClickDistrict,
                                       handleClickOrder,
                                       searchCriteria,
                                       summary
                                   }) => {
    const containerId = "dbpie-summary";

    const first = (event) => {
        event.preventDefault();
        moveToFirstPage();
    }

    const moveToFirstPage = () => {
        let criteria = {...searchCriteria};
        criteria.First();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const last = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Last(summary.length);
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const next = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Next();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const previous = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Previous();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    useEffect(() => {
        if (!searchCriteria.onValidPage(summary.length))
            moveToFirstPage();

    }, [summary.length]);

    const totalColumns = 6;

    return (
        <>
            <GridTable id={containerId} className={`dbpie-summary-table`}>
                <thead>
                <tr>
                    <th onClick={handleClickOrder} data-id="datesCovered" className="is-clickable">
                        Date Window
                    </th>
                    <th onClick={handleClickOrder} data-id="districtName" className="is-clickable">
                        District
                    </th>
                    <th onClick={handleClickOrder} data-id="statusOutput" className="is-clickable">
                        Status
                    </th>
                    <th onClick={handleClickOrder} data-id={`dateStatusUpdated`} data-sorttype={SortType.Date}
                        className={`is-clickable`}>
                        Date Status Updated
                    </th>
                    <th onClick={handleClickOrder} data-id={`dateMeetingHeld`} data-sorttype={SortType.Date}
                        className={`is-clickable`}>
                        Date Meeting Held
                    </th>
                    <th className={`text-center`}>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    isArrayNullOrEmpty(summary) &&
                    <tr>
                        <td colSpan={totalColumns} className={"center"}>
                            No results match the search criteria specified.
                        </td>
                    </tr>
                }
                {
                    searchCriteria.GetCurrentPageRecords(summary).map((district, key) => {
                        const isEditable = CanEditDistrictBpie(district, finFacilitatorDistricts);
                        const buttonType = isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW;
                        const labelType = isEditable ? "Edit" : "View";
                        return (<tr key={key}>
                            <td>{district.datesCovered}</td>
                            <td>{district.districtName}</td>
                            <td>{district.statusOutput}</td>
                            <td>{district.dateStatusUpdated}</td>
                            <td>{district.dateMeetingHeld}</td>
                            <td className={`actions text-center`}>
                                <Button
                                    buttonType={buttonType}
                                    label={`${labelType} District BPIE for ${district.districtName}`}
                                    name={`btnDistrictDetails`}
                                    onClick={(event) => handleClickDistrict(event, district.districtId, district.districtBpieId)}
                                    showLabel={false}
                                />
                            </td>
                        </tr>);
                    })
                }
                </tbody>
                <>
                    {
                        summary.length > 0 &&
                        <tfoot>
                        <tr>
                            <td colSpan={totalColumns}>
                                <ResultsPager
                                    currentPage={searchCriteria.Page}
                                    disabled={false}
                                    first={first}
                                    last={last}
                                    next={next}
                                    previous={previous}
                                    recordsPerPage={searchCriteria.GetRecordsPerPage(summary.length)}
                                    totalRecords={summary.length}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    }
                </>
            </GridTable>
        </>
    );
};

DbpieSearchResults.propTypes = {
    actions: PropTypes.object.isRequired,
    finFacilitatorDistricts: PropTypes.array,
    handleClickDistrict: PropTypes.func.isRequired,
    handleClickOrder: PropTypes.func.isRequired,
    searchCriteria: PropTypes.object.isRequired,
    summary: PropTypes.array
};