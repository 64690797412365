import React, {useEffect, useState} from 'react';
import {deepCopyArray, isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import PropTypes from "prop-types";
import AuditHistory from "../../../components/audit/AuditHistory";
import {newComment} from "../../../components/shared/sharedDataUtilities";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../../constants/AuditConstants";

export const PtsAuditCommentsView = ({
                                              canAudit = false,
                                              handleSaveAuditCommentAsync,
                                              isStateUser,
                                              quarterlyAudits,
                                          }) => {
    const [updatedAudits, setUpdatedAudits] = useState(quarterlyAudits);
    const [editAuditId, setEditAuditId] = useState('');
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [reloadAudits, setReloadAudits] = useState(false);

    const clearEditMode = () => {
        setEditAuditId('');
        setIsInEditMode(false);
    };

    const handleCreateComment = (auditId) => {
        let tempAudits = deepCopyArray(updatedAudits);
        let audit = tempAudits.filter(a => a.auditId === auditId)[0];
        audit.comments.unshift(newComment(auditId));
        setUpdatedAudits(tempAudits);
        setIsInEditMode(true);
    };

    const handleEditComment = (commentId) => {
        setEditAuditId(commentId);
        setIsInEditMode(true);
    };

    const handleCancelComment = () => {
        setUpdatedAudits(deepCopyArray(quarterlyAudits));
        clearEditMode();
    };

    const handleChangeComment = (event, updateComment) => {
        event.preventDefault();
        let tempAudits = deepCopyArray(updatedAudits);

        const fieldUpdated = event.target.name;
        const updatedValue = event.target.value;

        let updatedComment = tempAudits.find(a => a.auditId === updateComment.auditId)
            .comments
            .find(c => c.auditCommentId === updateComment.auditCommentId);

        if (fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
            updatedComment.isVisibleToDistrict = updatedValue;
        else
            updatedComment.text = updatedValue;

        setUpdatedAudits(tempAudits);
    };


    const handleSaveComment = async (event, comment, auditId) => {
        event.preventDefault();
        const isSuccess = await handleSaveAuditCommentAsync(auditId, comment);

        if (isSuccess) {
            clearEditMode();
            setReloadAudits(true);
        }
    };

    useEffect(() => {
        if(quarterlyAudits.length !== updatedAudits.length || reloadAudits) {
            setUpdatedAudits(quarterlyAudits);
            setReloadAudits(false);
        }
    }, [quarterlyAudits, reloadAudits]);

    if (isArrayNullOrEmpty(quarterlyAudits))
        return null;

    if (!isStateUser || !canAudit)
        return <AuditHistory
            audits={updatedAudits}
            isHistoryOpen
            title={`Quarterly Report Submission History`}
        />;

    return <>
        <AuditHistory
            audits={updatedAudits}
            editItemId={editAuditId}
            isInEditMode={isInEditMode}
            isHistoryOpen
            isLoading={false}
            labelForVisibility={`Visible to Projects`}
            onCommentCreate={handleCreateComment}
            onCommentCancel={handleCancelComment}
            onCommentEdit={handleEditComment}
            onCommentSave={handleSaveComment}
            handleCommentChange={handleChangeComment}
            parentIdPropName={`auditId`}
            title={`Quarterly Report Submission History`}
        />
    </>;
};

PtsAuditCommentsView.propTypes = {
    canAudit: PropTypes.bool.isRequired,
    handleSaveAuditCommentAsync: PropTypes.func.isRequired,
    isStateUser: PropTypes.bool.isRequired,
    quarterlyAudits: PropTypes.arrayOf(PropTypes.object)
};