import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import DateTimePickerField from "../../../../components/common/inputs/DateTimePickerField";
import TextArea from "../../../../components/common/inputs/TextArea";
import bpieApi from "../../bpieApi";
import FormTable from "../../../../components/common/FormTable";
import SelectField from "../../../../components/common/inputs/SelectField";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, createYesNoList, createYesNoNotApplicableList, createYesNoObject,
    inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import Button from "../../../../components/common/buttons/Button";
import { NotifyUser } from "../../../../components/common/NotifyUser";
import TextField from "../../../../components/common/inputs/TextField";
import { isNullOrUndefined, isTrimmedStringEmpty, createListFromArray } from "../../../../components/common/commonUtilities";
import RadioField from "../../../../components/common/inputs/RadioField";
import { inputStyles } from "../../../../constants/inputConstants";
import { createFinFacilitatorsList } from "../../bpieUtilities";
import {createSchoolInfoDetails, createTrainingResponse} from "./bpieSchoolInfoFactory";
import {meetingLeaderTitles, meetingLeaderTitlesList, schoolBpieTrainingStatus} from "./bpieSchoolInfoConstants";
import VimeoDisplay from "../../../../components/display/VimeoDisplay";

const BpieAssignMemberForm = ({
    actions,
    handleDataChanged,
    handleSaveSchoolInfoDetails,
    schoolBpie
}) => {

    const [finFacilitatorList, setFinFacilitatorList] = useState(null);
    const [trainingStatus, setTrainingStatus] = useState(schoolBpie.trainingStatus);

    const bpieMemberForm = useFormInputObject(createSchoolInfoDetails(schoolBpie), () => handleDataChanged());
    const onSaveClick = (event) => {
        event.preventDefault();
        if(trainingStatus === schoolBpieTrainingStatus.TakeTraining)
            setTrainingStatus(schoolBpieTrainingStatus.TakeAssessment);
        else if(trainingStatus === schoolBpieTrainingStatus.FailedTraining)
            setTrainingStatus(schoolBpieTrainingStatus.TakeTraining);
        else
            saveTeamMate();
    };

    const saveTeamMate = () => {
        const isValid = validateForm();
        if (isValid) {
            const isMeetingLeaderFinFacilitator = bpieMemberForm.title.value === meetingLeaderTitles.FINFacilitator;
            if (isMeetingLeaderFinFacilitator)
                bpieMemberForm.hasCompletedBpieTraining.setValue(false);
            else if(!isMeetingLeaderFinFacilitator && trainingStatus !== schoolBpieTrainingStatus.PassedTraining) {
                bpieMemberForm.assessmentTeam.setValue("");
                bpieMemberForm.dateMeetingHeld.setValue("");
            }

            const result = convertFormInputObjectToObject(bpieMemberForm);

            let responses = [];
            if (trainingStatus !== schoolBpieTrainingStatus.PassedTraining && !isMeetingLeaderFinFacilitator) {
                let index = 0;
                for(let question of schoolBpie.bpieTrainingQuestions) {
                    const response = createTrainingResponse(question.trainingQuestion, bpieMemberForm[`response${index}`].value);
                    responses.push(response);
                    bpieMemberForm[`response${index}`].setValue("");
                    index++;
                }
            }
            result.responses = responses;

            handleSaveSchoolInfoDetails(result);

            if(trainingStatus !== schoolBpieTrainingStatus.PassedTraining)
                setTrainingStatus(schoolBpieTrainingStatus.FailedTraining);
        }
    };

    const isOtherSelected = () => {
        return bpieMemberForm.title.value === meetingLeaderTitles.Other;
    }

    const isAdditionalMeetingLeaderInfoRequired = () => {
        const title = bpieMemberForm.title.value;
        return title !== meetingLeaderTitles.SchoolPrincipal
            && title !== meetingLeaderTitles.FINFacilitator
            && title !== "";
    }

    const validateForm = () => {
        clearInputFormErrorFields(bpieMemberForm);

        if (isTrimmedStringEmpty(bpieMemberForm.hasMetEssaTarget.value))
            bpieMemberForm.hasMetEssaTarget.setError("Required");

        if (isTrimmedStringEmpty(bpieMemberForm.finFacilitatorId.value))
            bpieMemberForm.finFacilitatorId.setError("Required");

        if (isTrimmedStringEmpty(bpieMemberForm.title.value))
            bpieMemberForm.title.setError("Required");

        if (isTrimmedStringEmpty(bpieMemberForm.titleOther.value) && isOtherSelected())
            bpieMemberForm.titleOther.setError("Required");

        if (isTrimmedStringEmpty(bpieMemberForm.firstName.value) && isAdditionalMeetingLeaderInfoRequired())
            bpieMemberForm.firstName.setError("Required");

        if (isTrimmedStringEmpty(bpieMemberForm.lastName.value) && isAdditionalMeetingLeaderInfoRequired())
            bpieMemberForm.lastName.setError("Required");

        if (isTrimmedStringEmpty(bpieMemberForm.email.value) && isAdditionalMeetingLeaderInfoRequired())
            bpieMemberForm.email.setError("Required");

        if (isNullOrUndefined(bpieMemberForm.hasCompletedBpieTraining.value) && bpieMemberForm.title.value !== meetingLeaderTitles.FINFacilitator)
            bpieMemberForm.hasCompletedBpieTraining.setError("Required");

        if(trainingStatus === schoolBpieTrainingStatus.TakeAssessment) {
            let index = 0;
            for(let question of schoolBpie.bpieTrainingQuestions) {
                if(isTrimmedStringEmpty(bpieMemberForm[`response${index}`].value))
                    bpieMemberForm[`response${index}`].setError("Required");
                index++;
            }
        }

        if (trainingStatus === schoolBpieTrainingStatus.PassedTraining && isTrimmedStringEmpty(bpieMemberForm.assessmentTeam.value))
            bpieMemberForm.assessmentTeam.setError("Required");

        if (trainingStatus === schoolBpieTrainingStatus.PassedTraining && isTrimmedStringEmpty(bpieMemberForm.dateMeetingHeld.value))
            bpieMemberForm.dateMeetingHeld.setError("Required");

        let isValid = inputFormIsValid(bpieMemberForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const isFINFacilitator = () => {
        return bpieMemberForm.title.value === meetingLeaderTitles.FINFacilitator;
    }

    const getSaveButtonLabel = () => {
        if(isFINFacilitator())
            return "Save SBPIE Assessment Information";

        let saveButtonLabel = "";
        switch (trainingStatus) {
            case schoolBpieTrainingStatus.PassedTraining:
                saveButtonLabel = "Save SBPIE Assessment Information";
                break;
            case schoolBpieTrainingStatus.TakeTraining:
                saveButtonLabel = "Take the Assessment";
                break;
            case schoolBpieTrainingStatus.TakeAssessment:
                saveButtonLabel = "Save Training Assessment";
                break;
            case schoolBpieTrainingStatus.NotSelected:
                saveButtonLabel = "Save";
                break;
            case schoolBpieTrainingStatus.FailedTraining:
                saveButtonLabel = "Review the Training";
                break;
        }
        return saveButtonLabel;
    }

    useEffect(() => {
        actions.executeApi(bpieApi.GetFinUsersByDistrict)
            .then(finFacilitators => setFinFacilitatorList(createFinFacilitatorsList(finFacilitators)));
    }, []);

    useEffect(() => {
        if(isNullOrUndefined(bpieMemberForm.hasCompletedBpieTraining.value)) {
            setTrainingStatus(schoolBpieTrainingStatus.NotSelected);
            return;
        }
        const hasCompleted = bpieMemberForm.hasCompletedBpieTraining.value === createYesNoObject().Yes;
        setTrainingStatus( hasCompleted ? schoolBpieTrainingStatus.TakeAssessment : schoolBpieTrainingStatus.TakeTraining);
    }, [bpieMemberForm.hasCompletedBpieTraining.value]);

    useEffect(() => {
        if(schoolBpie.hasPassedBpieTraining)
            setTrainingStatus(schoolBpieTrainingStatus.PassedTraining);
    }, [schoolBpie.hasPassedBpieTraining])

    return (
        <>
            <h2>ESSA Targets</h2>
            <FormTable>
                <RadioField
                    {...bpieMemberForm.hasMetEssaTarget}
                    direction={inputStyles.radio.direction.HORIZONTAL}
                    legend={`Students with disabilities subgroup met the target for <a href=' https://www.fldoe.org/academics/essa.stml' target='_blank' rel='noopener noreferrer'>Every Student Succeeds Act (ESSA)</a> Federal Percent of Points Index.`}
                    legendClass={`font-normal-weight`}
                    options={createYesNoNotApplicableList()}
                />
            </FormTable>

            <h2>FIN Facilitator Contact Name</h2>
            <SelectField
                {...bpieMemberForm.finFacilitatorId}
                defaultOption={`Select FIN Facilitator`}
                includeDefaultOption
                label={`FIN Facilitator Contact Name`}
                medium={6}
                options={finFacilitatorList}
            />

            <h2>Assign your School&apos;s FIN Trained Meeting Leader</h2>
                <FormTable>
                    <div>
                        <SelectField
                            {...bpieMemberForm.title}
                            defaultOption={`Select Title`}
                            includeDefaultOption
                            inputClass={"row-margin-revert medium-6"}
                            label={`Meeting Leader Title`}
                            options={meetingLeaderTitlesList}
                            showLabel
                        />
                        {
                            isOtherSelected() &&
                            <TextField
                                {...bpieMemberForm.titleOther}
                                label="Meeting Leader Title Other Value"
                                showLabel
                            />
                        }
                    </div>
                    {
                        isAdditionalMeetingLeaderInfoRequired() &&
                        <>
                            <TextField
                                {...bpieMemberForm.firstName}
                                label="Meeting Leader First Name"
                                showLabel
                            />
                            <TextField
                                {...bpieMemberForm.lastName}
                                label="Meeting Leader Last Name"
                                showLabel
                            />
                            <TextField
                                {...bpieMemberForm.email}
                                label="Meeting Leader Email Address"
                                showLabel
                            />
                        </>
                    }
                {
                    bpieMemberForm.title.value !== "" &&
                    bpieMemberForm.title.value !== meetingLeaderTitles.FINFacilitator &&
                    <div>
                        {
                            trainingStatus !== schoolBpieTrainingStatus.PassedTraining &&
                            <RadioField
                                {...bpieMemberForm.hasCompletedBpieTraining}
                                legend={`Has the Meeting Leader completed the SBPIE Assessment training previously?`}
                                options={createYesNoList()}
                                direction={inputStyles.radio.direction.HORIZONTAL}
                            />
                        }
                        {
                            trainingStatus === schoolBpieTrainingStatus.TakeAssessment &&
                            <>
                                {
                                    schoolBpie.bpieTrainingQuestions.map((question, index) => {
                                        return (
                                            <RadioField
                                                {...bpieMemberForm[`response${index}`]}
                                                key={index}
                                                legend={`${index + 1}. ${question.trainingQuestion}`}
                                                options={createListFromArray(question.trainingOptions)}
                                            />
                                        );
                                    })
                                }
                            </>
                        }

                        {
                            trainingStatus === schoolBpieTrainingStatus.FailedTraining &&
                            <p>
                                <strong>You have failed the CYK. You may review the SBPIE FIN-Trained Leader video if
                                    needed and please retake the CYK again. </strong>
                            </p>
                        }
                        {
                            trainingStatus === schoolBpieTrainingStatus.TakeTraining &&
                            <VimeoDisplay videoId="880620555/?h=5fa9850166"/>
                        }
                        {
                            trainingStatus === schoolBpieTrainingStatus.PassedTraining &&
                            <p><strong>Thank you for completing the CYK correctly, you may proceed with completing the SBPIE
                                assessment.</strong></p>
                        }
                    </div>
                }
                    {
                        (trainingStatus === schoolBpieTrainingStatus.PassedTraining || isFINFacilitator()) &&
                    <>
                        <TextArea
                            {...bpieMemberForm.assessmentTeam}
                            label={'Add all team member names and titles/positions.'}
                            showLabel
                        />

                        <DateTimePickerField
                            {...bpieMemberForm.dateMeetingHeld}
                            inputClass={`input--small`}
                            label="Date Meeting Held"
                            showLabel
                        />
                    </>
                }
            </FormTable>
            <ButtonBar>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={getSaveButtonLabel()}
                    name="btnSave"
                    disabled={trainingStatus === schoolBpieTrainingStatus.NotSelected && !isFINFacilitator()}
                    onClick={onSaveClick}
                />
            </ButtonBar>
        </>
    );
}

BpieAssignMemberForm.propTypes = {
    actions: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveSchoolInfoDetails: PropTypes.func.isRequired,
    schoolBpie: PropTypes.object.isRequired
};

export default BpieAssignMemberForm;