import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import {createBpieIndicator, createBpieResponse} from "../Assessment/bpieAssessmentFactory";
import {DBPIE_ASSESSMENT_STATUS} from "../bpieConstants";

export const createSbpieError = ({
                                     errorMessage,
                                     msidNumber,
                                     principalEmail,
                                     principalFirstName,
                                     principalLastName,
                                     schoolBpieErrorId,
                                     schoolName
                                 } = {}) => (
    {
        errorMessage: errorMessage || "",
        msidNumber: msidNumber || "",
        principalEmail: principalEmail || "",
        principalFirstName: principalFirstName || "",
        principalLastName: principalLastName || "",
        schoolBpieErrorId,
        schoolName: schoolName || ""
    }
);

export const createDbpie = ({
                                assessmentTeam,
                                autoApproveDate,
                                bpieStatus,
                                bpieStatusDescription,
                                canDistrictEdit,
                                canFinAdminEdit,
                                canFinFacilitatorEdit,
                                dateMeetingHeld,
                                dateMeetingHeldOutput,
                                datesCovered,
                                district,
                                districtBpieId,
                                districtContactEmail,
                                districtContactFirstName,
                                districtContactLastName,
                                districtFeedback = "",
                                districtId,
                                indicators,
                                nextBpieStatus,
                                nextBpieStatusDescription,
                                responses,
                                userId
                            } = {}, isEditable, isDistrictUser, canSubmit) => {
    const isFinalized = bpieStatus === DBPIE_ASSESSMENT_STATUS.Finalized.id;
    const hasDistrictFeedback = !isTrimmedStringEmpty(districtFeedback);
    const disableDistrictFeedback = !isDistrictUser || !isEditable;
    const displayDistrictFeedback = (hasDistrictFeedback && !isFinalized) || (isEditable && isDistrictUser);

    return {
        assessmentTeam: assessmentTeam || "",
        autoApproveDate: autoApproveDate || "",
        canDistrictEdit: isNullOrUndefined(canDistrictEdit) ? false : canDistrictEdit,
        canFinAdminEdit: isNullOrUndefined(canFinAdminEdit) ? false : canFinAdminEdit,
        canFinFacilitatorEdit: isNullOrUndefined(canFinFacilitatorEdit) ? true : canFinFacilitatorEdit,
        canSubmit,
        dateMeetingHeld: dateMeetingHeld || "",
        dateMeetingHeldOutput: dateMeetingHeldOutput || "",
        districtBpieStatus: bpieStatus || "",
        districtBpieStatusDescription: bpieStatusDescription || "",
        datesCovered: datesCovered || "",
        disableDistrictFeedback,
        displayDistrictFeedback,
        districtBpieId: districtBpieId || "",
        districtContactEmail: districtContactEmail || "",
        districtContactFirstName: districtContactFirstName || "",
        districtContactLastName: districtContactLastName || "",
        districtFeedback: districtFeedback || "",
        districtId: districtId || "",
        districtName: district || "",
        indicators: isNullOrUndefined(indicators) ? [] : indicators.map(i => createBpieIndicator(i)),
        isFinalized,
        isEditable,
        nextDistrictBpieStatus: nextBpieStatus || "",
        nextDistrictBpieStatusDescription: nextBpieStatusDescription || "",
        responses: isNullOrUndefined(responses) ? [] : responses.map(r => createBpieResponse(r)),
        userId: userId || ""
    };
}

export const createDistrictInfoDetails = ({
                                              assessmentTeam,
                                              dateMeetingHeld,
                                              userId
                                          } = {}) => (
    {
        assessmentTeam: assessmentTeam || "",
        dateMeetingHeld: dateMeetingHeld || "",
        userId: userId || ""
    }
);