import {
    createDescriptionObjectForList, deepCopyObject, convertToBoolean, isTrimmedStringEmpty,
} from "../../components/common/commonUtilities";
import {emptyGuid} from "../../constants/config";
import {ACTIVITY_CATEGORIES, DISCRETIONARY_PROJECT_QUARTERS, QUARTERLY_REPORT_STATUS} from "./ptsConstants";
import {getCurrentDateTime} from "../../components/common/dateUtilities";

export const createDiscretionaryProjectObject = ({
                                                     activities,
                                                     budgets,
                                                     budgetTotalDisplay,
                                                     datesCovered,
                                                     discretionaryProjectAudits,
                                                     discretionaryProjectId,
                                                     discretionaryProjectStatus,
                                                     discretionaryProjectUsers,
                                                     doeProjectLiaison,
                                                     firstQuarterNotApproved,
                                                     grantManagementLiaison,
                                                     hideAbilityToSubmit,
                                                     implementationCount = 0,
                                                     isCompleted,
                                                     isSubmissionDisabled,
                                                     programFiscalAgent,
                                                     projectEndDate,
                                                     projectEndDateOutput,
                                                     projectManagers,
                                                     projectName,
                                                     projectStartDate,
                                                     projectStartDateOutput,
                                                     quarterlyReports,
                                                     requiresReturnOnInvestmentReport,
                                                     returnOnInvestmentFiles = [],
                                                     submissionMessage
                                                 }) => ({
    budgets: budgets?.map((i) => createBudgetObject(i)) ?? [],
    budgetTotalDisplay,
    datesCovered,
    discretionaryProjectAudits: discretionaryProjectAudits?.map((i) => createDiscretionaryProjectAuditObject(i)) ?? [],
    discretionaryProjectId,
    discretionaryProjectStatus,
    discretionaryProjectUsers: discretionaryProjectUsers?.map((i) => createProgramUserObject(i)) ?? [],
    doeProjectLiaison: createProgramUserObject(doeProjectLiaison ?? {}),
    firstQuarterNotApproved,
    grantManagementLiaison: createProgramUserObject(grantManagementLiaison ?? {}),
    hideAbilityToSubmit,
    implementationCount,
    isCompleted,
    isSubmissionDisabled,
    productActivities: getActivities(activities, ACTIVITY_CATEGORIES.product.id),
    programFiscalAgent: createProgramFiscalAgentObject(programFiscalAgent),
    projectEndDate,
    projectEndDateOutput,
    projectManagers: projectManagers?.map((i) => createProgramUserObject(i)) ?? [],
    projectName,
    projectStartDate,
    projectStartDateOutput,
    quarterlyReports: quarterlyReports?.map((i) => createQuarterlyReportObject(i)) ?? [],
    requiresReturnOnInvestmentReport,
    returnOnInvestmentFiles: returnOnInvestmentFiles?.map((i) => createReturnOnInvestmentFiles(i)) ?? [],
    serviceDeliveryActivities: getActivities(activities, ACTIVITY_CATEGORIES.serviceDelivery.id),
    submissionMessage,
    trainingActivities: getActivities(activities, ACTIVITY_CATEGORIES.training.id)
});

const createQuarterlyReportObject = ({
                                         canEditImplementations = false,
                                         dateRange = {},
                                         isQuarterlyReportApproved = false,
                                         quarter = DISCRETIONARY_PROJECT_QUARTERS.q1.id,
                                         quarterlyReportStatus = QUARTERLY_REPORT_STATUS.notStarted.id,
                                         quarterlyReportStatusOutput = ""
                                     }) => ({
    canEditImplementations,
    datesCovered: dateRange.datesCovered,
    endDate: dateRange.endDate,
    isQuarterlyReportApproved,
    quarter,
    quarterlyReportStatus,
    quarterlyReportStatusOutput,
    startDate: dateRange.startDate
});

const createReturnOnInvestmentFiles = ({
                                           discretionaryProjectQuarter = DISCRETIONARY_PROJECT_QUARTERS.q1,
                                           displayName = "",
                                           length = 0,
                                           name = "",
                                           readableLength = "",
                                           roiDocumentationFileId = ""
                                       }) => ({
    discretionaryProjectQuarter,
    displayName,
    length,
    name,
    readableLength,
    roiDocumentationFileId
});

function getActivities(activities, category) {
    return activities?.filter(f => f.activityCategory === category)?.map((i) => createActivityObject(i)) ?? [];
}

export const createActivityObject = ({
                                         activityId = emptyGuid,
                                         activityCategory = 0,
                                         activityDescription = "",
                                         activityNumber = 0,
                                         activityNumberOutput = "",
                                         activityTitle = "",
                                         implementations,
                                         sourceDocumentationFiles = [],
                                     }) => {
    let createImplementationCategoryObject = () => {
    };
    switch (activityCategory) {
        case ACTIVITY_CATEGORIES.training.id:
            createImplementationCategoryObject = createTrainingImplementationObject;
            break;
        case ACTIVITY_CATEGORIES.product.id:
            createImplementationCategoryObject = createProductImplementationObject;
            break;
        case ACTIVITY_CATEGORIES.serviceDelivery.id:
            createImplementationCategoryObject = createServiceDeliveryImplementationObject;
            break;
    }

    return {
        activityId,
        activityCategory,
        activityDescription,
        activityNumber,
        activityNumberOutput,
        activityTitle,
        implementations: implementations?.map((i) => createImplementationCategoryObject(i)) ?? [],
        sourceDocumentationFiles: sourceDocumentationFiles?.map((i) => createSourceDocumentationFileObject(i)) ?? [],
    };
};

export const createSourceDocumentationFileObject = ({
                                                        discretionaryProjectQuarter = DISCRETIONARY_PROJECT_QUARTERS.q1,
                                                        displayName = "",
                                                        length = 0,
                                                        name = "",
                                                        readableLength = "",
                                                        sourceDocumentationFileId = ""
                                                    }) => ({
    discretionaryProjectQuarter,
    displayName,
    length,
    name,
    readableLength,
    sourceDocumentationFileId
});

export const createTrainingImplementationObject = (implementation = {}) => {
    const {
        correctiveAction = "",
        facilitator = "",
        hasDetails = false,
        startDate = "",
        startDateOutput = "",
        endDate = "",
        endDateOutput = "",
        numberOfTrainings = "",
        timeAndEffort = "",
        totalNumberOfTrainingParticipants = ""
    } = implementation;

    return {
        ...createImplementationObject(implementation),
        startDate: hasDetails ? startDate : "",
        startDateOutput,
        endDate,
        endDateOutput,
        facilitator,
        timeAndEffort,
        correctiveAction: correctiveAction || "",
        numberOfTrainings,
        totalNumberOfTrainingParticipants,
    };
};

export const createProductImplementationObject = (implementation = {}) => {
    const {
        contact = "",
        hasDetails = false,
        productDisseminatedDate = "",
        productDisseminatedDateOutput = "",
        numberDisseminated = "",
        howProductWasDisseminated = "",
        timeAndEffort = ""
    } = implementation;

    return {
        ...createImplementationObject(implementation),
        productDisseminatedDate: hasDetails ? productDisseminatedDate : "",
        productDisseminatedDateOutput,
        contact,
        timeAndEffort,
        howProductWasDisseminated,
        numberDisseminated
    };
};

export const createServiceDeliveryImplementationObject = (implementation = {}) => {
    const {
        dateOfService = "",
        dateOfServiceOutput = "",
        facilitator = "",
        hasDetails = false,
        timeAndEffort = "",
        totalNumberOfServiceDeliveryParticipants = ""
    } = implementation;
    return {
        ...createImplementationObject(implementation),
        dateOfService: hasDetails ? dateOfService : "",
        dateOfServiceOutput,
        facilitator,
        timeAndEffort,
        totalNumberOfServiceDeliveryParticipants
    };
};

const ALL_DISTRICTS = "All Districts";

const createImplementationObject = ({
                                        audience = null,
                                        canEditImplementation = true,
                                        collaborators = "",
                                        hasDetails = false,
                                        implementationId = emptyGuid,
                                        implementationQuarter = "",
                                        implementationDate = "",
                                        implementationDateOutput = "",
                                        implementationDetails = "",
                                        implementationName = ""
                                    }) => {
    const audienceDetails = createAudienceObject(audience ?? {});
    return {
        implementationDate,
        implementationDateOutput,
        implementationName,
        implementationDetails,
        audienceTypes: audienceDetails.audienceTypes,
        canEditImplementation,
        collaborators,
        districts: audienceDetails.districts.filter((district) => district !== ALL_DISTRICTS),
        hasDetails,
        implementationId,
        implementationQuarter,
        includeAllDistricts: audienceDetails.districts.some((district) => district === ALL_DISTRICTS),
        schoolTypes: audienceDetails.schoolTypes,
        totalParticipants: audienceDetails.totalParticipants
    };
};

const createAudienceObject = ({
                                  audienceTypes = [],
                                  districts = [],
                                  schoolTypes = [],
                              }) => ({
    audienceTypes,
    districts: districts.map((district) => district.districtName),
    totalParticipants: districts,
    schoolTypes
});

export const createTotalParticipantsObject = ({districtName = "", totalParticipants = ""}) => ({
    districtName,
    totalParticipants
});

export const createActivityCategoryObject = (id, description, className, activityPropertyName) => ({
    ...createDescriptionObjectForList(id, description),
    className,
    activityPropertyName
});

export const createSaveImplementationObject = (implementation) => {
    let updatedImplementation = deepCopyObject(implementation);

    updatedImplementation.audience = createSaveAudienceObject(updatedImplementation);

    const hasDetails = convertToBoolean(updatedImplementation.hasDetails);
    if (!hasDetails) {
        updatedImplementation.startDate = updatedImplementation.implementationDate;
        updatedImplementation.productDisseminatedDate = updatedImplementation.implementationDate;
        updatedImplementation.dateOfService = updatedImplementation.implementationDate;
    }

    return updatedImplementation;
};

const createSaveAudienceObject = ({
                                      audienceTypes = [],
                                      schoolTypes = [],
                                      totalParticipants = [],
                                      includeAllDistricts = false
                                  }) => {
    const isIncludeAllDistrictsChecked = convertToBoolean(includeAllDistricts);
    return {
        audienceTypes,
        districts: isIncludeAllDistrictsChecked
            ? [createTotalParticipantsObject({districtName: ALL_DISTRICTS, totalParticipants: null})]
            : createSaveDistrictArray(totalParticipants),
        schoolTypes
    };
};

const createSaveDistrictArray = (totalParticipants) => {
    return totalParticipants.map((totalParticipant) => {
        return createTotalParticipantsObject({
            districtName: totalParticipant?.districtName,
            totalParticipants: totalParticipant?.totalParticipants
        });
    });
};

export const createSaveSourceDocumentationObject = (fileId = "", quarter = DISCRETIONARY_PROJECT_QUARTERS.q1.id) => ({
    fileId,
    quarter
});

export const createSaveRoiObject = (fileId = "", quarter = DISCRETIONARY_PROJECT_QUARTERS.q1.id) => ({
    fileId,
    quarter
});

export const createImplementationScrollId = (implementationId) => `i-${implementationId}`;

export const createBudgetObject = ({
                                       agencyCode = "",
                                       grantNumber = "",
                                       projectNumber = "",
                                       tapsCode = "",
                                       finalAllocation = 0,
                                       finalAllocationDisplay = "",
                                       budgetProjectNumber = "",
                                   }) => ({
    agencyCode,
    grantNumber,
    projectNumber,
    tapsCode,
    finalAllocation,
    finalAllocationDisplay,
    budgetProjectNumber,
});

export const createProgramFiscalAgentObject = ({
                                                   programId = emptyGuid,
                                                   fiscalAgentId = emptyGuid,
                                                   fiscalAgentName = "",
                                                   isDisabled = false,
                                                   programUsers
                                               } = {}) => ({
    programId,
    fiscalAgentId,
    fiscalAgentName,
    isDisabled,
    programUsers: programUsers?.map(i => createProgramUserObject(i)) ?? [],
});

export const createProgramUserObject = ({
                                            applicationUserId = "",
                                            email = "",
                                            firstName = "",
                                            fullName = "",
                                            lastName = "",
                                            phoneNumber = "",
                                            programUserId = "",
                                            ptsRole,
                                        }) => ({
    applicationUserId,
    email,
    firstName,
    fullName,
    lastName,
    phoneNumber,
    programUserId,
    ptsRole,
});

export const createDiscretionaryProjectAuditObject = ({
                                                          auditCreatedBy = "",
                                                          auditId = emptyGuid,
                                                          comments,
                                                          dateCreated,
                                                          discretionaryProjectId = emptyGuid,
                                                          discretionaryProjectQuarter = DISCRETIONARY_PROJECT_QUARTERS.q1.id,
                                                          isFiscalAgentUser = false,
                                                          isLocked = false,
                                                          outcome,
                                                          outcomeString = "",
                                                      }) => ({
    auditCreatedBy,
    auditId,
    comments: comments?.map((i) => createAuditCommentObject(i)) ?? [],
    dateCreated,
    discretionaryProjectId,
    discretionaryProjectQuarter,
    isFiscalAgentUser,
    isLocked,
    outcome,
    outcomeString,
});

export const createAuditCommentObject = ({
                                             applicationUserId = "",
                                             auditCommentId,
                                             auditId,
                                             auditorFirstName = "",
                                             auditorLastName = "",
                                             commentOwnerEmail = "",
                                             currentUserId = "",
                                             dateCreated = getCurrentDateTime(),
                                             dateModified,
                                             displayDate = getCurrentDateTime(),
                                             isCommentOwner = true,
                                             isVisibleToDistrict = true,
                                             text = ""
                                         }) => ({
    applicationUserId,
    auditCommentId: isTrimmedStringEmpty(auditCommentId) ? emptyGuid : auditCommentId,
    auditId,
    auditorFirstName,
    auditorLastName,
    commentOwnerEmail,
    currentUserId,
    dateCreated,
    dateModified,
    displayDate,
    isCommentOwner,
    isVisibleToDistrict,
    text,
});

export const createReportObject = () => ({
    reportType: "",
    dateRangeType: "",
    startDate: "",
    endDate: "",
});