import Location from "../../components/shared/Location";

export const BPIE_STATUS_DASHBOARD_PARAM = "bpieStatus";
export const DATE_WINDOW_DASHBOARD_PARAM = "dateWindowId";
export const BPIE_DATE_WINDOW_ID = ":dateWindowId";
export const SCHOOL_BPIE_ID = ":schoolBpieId";
export const DISTRICT_BPIE_ID = ":districtBpieId";
export const INDICATOR_NUMBER = ":indicatorNumber";
export const BPIE_DISTRICT_ID = ":districtId";
export const BPIE_SCHOOL_NUMBER = ":schoolNumber";
export const API_ROOT = `bpies`;
export const ROOT = `/${API_ROOT}`;
export const SCHOOL_ROOT = `/${API_ROOT}/schools`;
export const DISTRICT_ROOT = `/${API_ROOT}/districts`;
export const STATE_ROOT = `/${API_ROOT}/admin`;
export const BPIE_REPORT_ROOT = `${API_ROOT}/reports`;

// ************************ SCHOOL BPIE ROUTES ********************************

export const BPIE_LANDING = Location(() => `${SCHOOL_ROOT}/schoolInfo`);
export const BPIE_LANDING_WITH_SCHOOL_BPIE = Location((schoolBpieId = SCHOOL_BPIE_ID) => `${SCHOOL_ROOT}/${schoolBpieId}/schoolInfo`);

export const BPIE_ASSESSMENT_INTRO = Location((schoolBpieId = SCHOOL_BPIE_ID) => `${SCHOOL_ROOT}/${schoolBpieId}/intro`);

export const BPIE_ASSESSMENT = Location((schoolBpieId = SCHOOL_BPIE_ID, indicatorNumber = INDICATOR_NUMBER) =>
        `${SCHOOL_ROOT}/${schoolBpieId}/assessment/${indicatorNumber}`);

export const BPIE_ASSESSMENT_SUMMARY = Location((schoolBpieId = SCHOOL_BPIE_ID) => `${SCHOOL_ROOT}/${schoolBpieId}/summary`);
export const BPIE_ASSESSMENT_PRIORITIES = Location((schoolBpieId = SCHOOL_BPIE_ID) => `${SCHOOL_ROOT}/${schoolBpieId}/priorities`);

export const AllSchoolBpieRoutes = [
    BPIE_LANDING,
    BPIE_LANDING_WITH_SCHOOL_BPIE,
    BPIE_ASSESSMENT_INTRO,
    BPIE_ASSESSMENT,
    BPIE_ASSESSMENT_PRIORITIES,
    BPIE_ASSESSMENT_SUMMARY
];

// ************************ DISTRICT BPIE ROUTES ********************************

export const DISTRICT_BPIE_SUMMARY = Location(() => `${DISTRICT_ROOT}/summary`);
export const DISTRICT_SBPIE_SEARCH = Location(() => `${SCHOOL_ROOT}/sbpieAssessments`);
export const DISTRICT_DBPIE_SEARCH = Location(() => `${DISTRICT_ROOT}/dbpieAssessments`);
export const DISTRICT_SCHOOL_SUMMARY = Location((districtId = BPIE_DISTRICT_ID, schoolNumber = BPIE_SCHOOL_NUMBER, schoolBpieId = SCHOOL_BPIE_ID) => `${DISTRICT_ROOT}/${districtId}/schools/${schoolNumber}/sbpies/${schoolBpieId}`);
export const DISTRICT_DBPIE_INFO = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID) => `${DISTRICT_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/info`);
export const DISTRICT_DBPIE_SUMMARY = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID) => `${DISTRICT_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/summary`);
export const DISTRICT_DBPIE_ASSESSMENT = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID, indicatorNumber = INDICATOR_NUMBER) => `${DISTRICT_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/assessment/${indicatorNumber}`);
export const DISTRICT_DBPIE_PRIORITIES = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID) => `${DISTRICT_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/priorities`);

export const AllDistrictBpieRoutes = [
    DISTRICT_BPIE_SUMMARY,
    DISTRICT_SBPIE_SEARCH,
    DISTRICT_DBPIE_SEARCH,
    DISTRICT_SCHOOL_SUMMARY,
    DISTRICT_DBPIE_INFO,
    DISTRICT_DBPIE_SUMMARY,
    DISTRICT_DBPIE_ASSESSMENT,
    DISTRICT_DBPIE_PRIORITIES
];

// ************************ STATE ADMIN BPIE ROUTES ********************************

export const STATE_BPIE_IMPORT = Location(() => `${STATE_ROOT}/ImportSchools`);
export const STATE_BPIE_REGIONS = Location(() => `${STATE_ROOT}/regions`);
export const STATE_SBPIE_CREATE = Location(() => `${STATE_ROOT}/CreateSchoolBpies`);
export const STATE_MANAGE_FIN = Location(() => `${STATE_ROOT}/ManageFins`);
export const STATE_SBPIE_SEARCH = Location(() => `${STATE_ROOT}/sbpieAssessments`);
export const STATE_DBPIE_SEARCH = Location(() => `${STATE_ROOT}/dbpieAssessments`);
export const STATE_SCHOOL_SUMMARY = Location((districtId = BPIE_DISTRICT_ID, schoolNumber = BPIE_SCHOOL_NUMBER, schoolBpieId = SCHOOL_BPIE_ID) => `${STATE_ROOT}/districts/${districtId}/schools/${schoolNumber}/sbpies/${schoolBpieId}`);
export const STATE_DBPIE_INFO = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID) => `${STATE_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/info`);
export const STATE_DBPIE_SUMMARY = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID) => `${STATE_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/summary`);
export const STATE_DBPIE_ASSESSMENT = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID, indicatorNumber = INDICATOR_NUMBER) => `${STATE_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/assessment/${indicatorNumber}`);
export const STATE_DBPIE_PRIORITIES = Location((dateWindowId = BPIE_DATE_WINDOW_ID, districtId = BPIE_DISTRICT_ID, districtBpieId = DISTRICT_BPIE_ID) => `${STATE_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/priorities`);

export const AllStateBpieRoutes = [
    STATE_BPIE_IMPORT,
    STATE_BPIE_REGIONS,
    STATE_SBPIE_CREATE,
    STATE_MANAGE_FIN,
    STATE_SBPIE_SEARCH,
    STATE_DBPIE_SEARCH,
    STATE_SCHOOL_SUMMARY,
    STATE_DBPIE_INFO,
    STATE_DBPIE_SUMMARY,
    STATE_DBPIE_ASSESSMENT,
    STATE_DBPIE_PRIORITIES
];