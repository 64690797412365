import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CheckBoxField from "./CheckBoxField";
import FieldsetLayout from "./FieldsetLayout";
import { inputStyles } from '../../../constants/inputConstants';
import { getRandomInt } from "../HtmlUtilities";
import { convertToArray } from "../commonUtilities";
import {
    appendedCheckboxOtherValue,
    createFakeEvent,
    generateOtherValue,
    isValueInArray,
    removeValueInArray,
    updateCheckboxOtherValue
} from "./inputUtility";
import TextAreaEditor from "./textAreaEditor/TextAreaEditor";

const CheckBoxList = ({
    direction,
    disabled,
    error,
    helpText,
    fieldsetClass,
    inputClass,
    labelClass,
    legend,
    legendClass,
    listHasTextField,
    name,
    onChange,
    options,
    showLabel,
    showLegend,
    useInputTheme,
    value,
    wrapperClass,
    align,
    small,
    showSelectAll
}) => {
    const uniqueId = getRandomInt();

    let selectedList = convertToArray(value);
    selectedList = [...selectedList].filter(a => a !== "");

    if (disabled)
        labelClass = `${labelClass} ${inputStyles.DISABLED}`;

    showLabel = options.length > 1 ? true : showLabel;

    const handleAdd = (newValue) => {
        selectedList.push(newValue);
        handleSetValue();
    };

    const handleRemove = (selectedValue) => {
        removeValueInArray(selectedList, selectedValue);
        handleSetValue();
    };

    const handleUpdate = (selectedValue, otherValue) => {
        updateCheckboxOtherValue(selectedList, selectedValue, otherValue);
        handleSetValue();
    };

    const handleSetValue = () => {
        if (selectedList.length === 0)
            onChange(createFakeEvent(``, name));
        else
            onChange(createFakeEvent(selectedList, name));
    };

    const handleChangeToggleSelectAll = () => {
        if (options.length === selectedList.length)
            selectedList = [];
        else {
            selectedList = options.map(option => option.value || option);
        }
        handleSetValue();
    };

    const toggleSelectAllLabel = options.length === selectedList.length
        ? "Deselect All"
        : "Select All";

    const toggleButtonChecked = options.length === selectedList.length;

    return (
        <FieldsetLayout
            disabled={disabled}
            error={error}
            helpText={helpText}
            fieldsetClass={fieldsetClass}
            legend={legend}
            legendClass={legendClass}
            showLegend={showLegend}
            useInputTheme={useInputTheme}
            wrapperClass={wrapperClass}
            align={align}
            small={small}
        >
            {
                showSelectAll &&
                <CheckBoxField
                    checked={toggleButtonChecked}
                    wrapperClass={`select-all`}
                    label={toggleSelectAllLabel}
                    name={`${name}_btnToggleSelectAll`}
                    onChange={handleChangeToggleSelectAll}
                    showLabel
                />
            }
            {
                options.map((option, index) => {
                    const optionValue = option.value || option;
                    const optionLabel = option.text || option;
                    const optionName = options.length > 1 ? `${name}_${index}_${uniqueId}` : name;
                    const hasTextField = isValueInArray(listHasTextField, optionValue);
                    const otherTextValue = hasTextField ? appendedCheckboxOtherValue(selectedList, optionValue) : "";
                    const appendedValue = otherTextValue ? generateOtherValue(optionValue, otherTextValue) : optionValue;
                    const isChecked = isValueInArray(selectedList, appendedValue, !hasTextField);
                    const handleChange = () => isChecked ? handleRemove(appendedValue) : handleAdd(optionValue);
                    const otherError = (!otherTextValue && !disabled) ? "More details required." : "";
                    return (
                        <Fragment key={index}>
                            <CheckBoxField
                                checked={isChecked}
                                inputClass={`${inputClass}`}
                                label={optionLabel}
                                labelClass={labelClass}
                                name={optionName}
                                onChange={handleChange}
                                showLabel={showLabel}
                                value={optionValue}
                                useInputTheme={useInputTheme}
                                wrapperClass={`${direction}`}
                            />
                            {
                                hasTextField &&
                                isChecked &&
                                <TextAreaEditor
                                    disabled={disabled}
                                    error={otherError}
                                    label={"More details"}
                                    name={`${optionName}_text`}
                                    onChange={(event) => handleUpdate(optionValue, event.target.value)}
                                    showLabel={false}
                                    value={otherTextValue}
                                    wrapperClass={`${inputStyles.textbox.OTHER}`}
                                />
                            }
                        </Fragment>
                    );
                })
            }
        </FieldsetLayout>
    );
};

CheckBoxList.propTypes = {
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    fieldsetClass: PropTypes.string,
    inputClass: PropTypes.string,
    labelClass: PropTypes.string,
    legend: PropTypes.string,
    legendClass: PropTypes.string,
    listHasTextField: PropTypes.array,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.oneOfType(
        [PropTypes.arrayOf(PropTypes.object), PropTypes.arrayOf(PropTypes.string)]
    ),
    showLabel: PropTypes.bool,
    showLegend: PropTypes.bool,
    useInputTheme: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number)
    ]).isRequired,
    wrapperClass: PropTypes.string,
    align: PropTypes.string,
    small: PropTypes.string,
    showSelectAll: PropTypes.bool
};

CheckBoxList.defaultProps = {
    direction: inputStyles.checkbox.direction.VERTICAL,
    disabled: false,
    listHasTextField: [],
    showLabel: false,
    showLegend: true,
    wrapperClass: "",
    showSelectAll: false
};

export default CheckBoxList;