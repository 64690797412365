import Location from "../../components/shared/Location";

export const API_ROOT = `Pts`;
export const ROOT = `/${API_ROOT}`;
export const PTS_STATE_ROOT = `/${API_ROOT}/Admin`;
export const PTS_DATE_WINDOW_ID = ":dateWindowId";
export const DATE_WINDOW_DASHBOARD_PARAM = "dateWindowId";
export const PROGRAM_ID = ":programId";
export const DISCRETIONARY_PROJECT_ID = ":discretionaryProjectId";

// ************************ SHARED ROUTES ************************
export const PTS_SUMMARY = Location(() => `${ROOT}/Summary`);
export const PTS_PROJECT_INFORMATION = Location((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Information`);
export const PTS_DELIVERABLES_SUMMARY = Location((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Deliverables`);
export const PTS_REPORTS = Location((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Reports`);

// ************************ STATE ADMIN ROUTES ********************************

export const PTS_ADMIN_GMS_USERS = Location(() => `${PTS_STATE_ROOT}/GmsUsers/`);
export const PTS_ADMIN_MANAGE_PROGRAMS = Location(() => `${PTS_STATE_ROOT}/Programs/`);
export const PTS_ADMIN_MANAGE_FISCAL_AGENTS_USERS = Location((programid = PROGRAM_ID) => `${PTS_STATE_ROOT}/Programs/${programid}/agentsUsers`);

export const ALL_PTS_ROUTES = [
    PTS_ADMIN_GMS_USERS,
    PTS_ADMIN_MANAGE_FISCAL_AGENTS_USERS,
    PTS_ADMIN_MANAGE_PROGRAMS,
    PTS_SUMMARY,
    PTS_PROJECT_INFORMATION,
    PTS_DELIVERABLES_SUMMARY,
    PTS_REPORTS
];