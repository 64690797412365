import {
    createDescriptionObjectForList,
    createListFromObject, createListItem
} from "../../components/common/commonUtilities";

export const BpieApiMessages = {
    ASSESSMENT:{
        UPDATE_RESPONSE:{
            SUCCESS: "Indicator response successfully saved"
        },
        SAVE_PRIORITIES:{
            SUCCESS: "Indicator priorities successfully saved"
        }
    },
    SBPIE: {
        DOWNLOAD: `SBPIE Assessments summary successfully downloaded.`,
        MSID_IMPORT_SUCCESS: "MSID file uploaded successfully.  See page for details."
    },
    SAVE_SCHOOL_BPIE:{
        SUCCESS: "SBPIE Assessment successfully saved",
        ADMIN_SAVE_SUCCESS: "School Administrator successfully saved",
    },
    ARCHIVE_SCHOOL_BPIE:{
        SUCCESS: "SBPIE Assessment successfully archived"
    },
    regions: {
        DISTRICT_SAVED: `The district list for a region was successfully saved.`,
        SAVED: `The region was successfully saved.`,
        DELETED: `The region was successfully deleted.`,
    },
    facilitators: {
        SAVED: `The facilitators have been successfully saved.`,
    },
    DISTRICT:{
        ACTIVATE_SBPIE: {
          SUCCESS: "SBPIE Assessment successfully activated and the principal notified"
        },
        STATUS_UPDATED: {
          SUCCESS: "SBPIE Assessment status successfully saved"
        },
        CREATE_SBPIE: {
          SUCCESS: "SBPIE Assessment successfully created and the principal notified"
        },
        NOTIFY_PRINCIPAL_START:{
            SUCCESS: "Principal successfully notified",
            RESEND_SUCCESS: "Principal successfully re-notified"
        },
        SBPIE_ERRORS: {
            FIXED: "The SBPIE has been fixed and the error removed."
        }
    },
    DBPIE: {
        UPDATED: "DBPIE Assessment successfully saved",
        STATUS_UPDATED: "DBPIE Assessment successfully submitted",
        FEEDBACK_SAVED: "DBPIE Assessment district feedback successfully saved"
    }
};

export const SBPIE_ASSESSMENT_STATUS = {
    Pending: createDescriptionObjectForList(1, "Pending"),
    Activated: createDescriptionObjectForList(2, "Activated"),
    Started: createDescriptionObjectForList(3, "Started"),
    InProgress: createDescriptionObjectForList(4, "In Progress"),
    Archived: createDescriptionObjectForList(5, "Archived"),
    Incomplete: createDescriptionObjectForList(6, "Incomplete"),
    NotApplicable: createDescriptionObjectForList(7, "Not Applicable")
};

const SBPIE_ACTIVATE_ASSESSMENT_STATUS = [SBPIE_ASSESSMENT_STATUS.Pending, SBPIE_ASSESSMENT_STATUS.Activated, SBPIE_ASSESSMENT_STATUS.NotApplicable];

export const DBPIE_ASSESSMENT_STATUS = {
    InProgress: createDescriptionObjectForList(11, "In Progress"),
    AdminReviewing: createDescriptionObjectForList(12, "FIN Admin Reviewing"),
    AdminReviewed: createDescriptionObjectForList(13, "FIN Admin Reviewed"),
    Draft: createDescriptionObjectForList(14, "District Reviewing"),
    DistrictReviewed: createDescriptionObjectForList(15, "District Reviewed"),
    Finalized: createDescriptionObjectForList(16, "Finalized")
}

export const sbpieAssessmentStatusList = createListFromObject(SBPIE_ASSESSMENT_STATUS);
export const sbpieActivateAssessmentStatusList = SBPIE_ACTIVATE_ASSESSMENT_STATUS.map((assessmentStatus) => createListItem(assessmentStatus.id, assessmentStatus.description));

export const dbpieAssessmentStatusList = createListFromObject(DBPIE_ASSESSMENT_STATUS);

export const SchoolBpieAssessmentSections = {
    SchoolInformation: "School Information",
    Intro: "Assessment Intro",
    Assessment: "Assessment",
};

export const bpieTeamRoles = {
    finFacilitator: "FinFacilitator",
    schoolAdministrator: "SchoolAdministrator",
    meetingLeader: "MeetingLeader"
}

export const DistrictBpieAssessmentSections = {
    DistrictInformation: "District Information",
    Assessment: "Assessment",
};