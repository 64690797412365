import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {QUARTERLY_REPORT_STATUS} from "../ptsConstants";

export const PtsResetAuditView = ({
                                      canAudit,
                                      handleResetClick,
                                      selectedQuarterlyReport
                                  }) => {
    const canBeReset = selectedQuarterlyReport?.quarterlyReportStatus === QUARTERLY_REPORT_STATUS.approved.id
        || selectedQuarterlyReport?.quarterlyReportStatus === QUARTERLY_REPORT_STATUS.needsRevision.id;

    if (!canAudit || !canBeReset)
        return null;

    return <>
        <div className={`resetAuditContainer`}>
            <Button
                buttonType={ButtonTypes.RESET}
                label={`Reset Back to Submitted`}
                name={`btnReset_${selectedQuarterlyReport.quarter}`}
                onClick={(event) => handleResetClick(event, selectedQuarterlyReport.quarter)}
            />
        </div>
    </>;
};

PtsResetAuditView.propTypes = {
    canAudit: PropTypes.bool.isRequired,
    handleResetClick: PropTypes.func.isRequired,
    selectedQuarterlyReport: PropTypes.object
};