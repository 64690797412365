import React from 'react';
import Button from "../../../components/common/buttons/Button";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DateSelectField} from "../../../components/common/inputs";
import {useFormInputObject} from "../../../components/common/inputs/inputUtility";
import SearchableSingleSelectField from "../../../components/common/inputs/SearchableSingleSelectField";
import SectionHeader from "../../../components/common/SectionHeader";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {dateSelectOptionChoices, gridConstants} from "../../../constants/inputConstants";
import {PTS_REPORT_TYPES_LIST} from "../ptsConstants";
import {createReportObject} from "../ptsObjectFactory";

export const PtsReportsView = () => {
    const reportForm = useFormInputObject(createReportObject());

    const handleSelectDate = (dateRangeType, startDate, endDate) => {
        reportForm.dateRangeType.setValue(dateRangeType);
        reportForm.startDate.setValue(startDate);
        reportForm.endDate.setValue(endDate);
    };

    const handleDownloadReport = (event) => {
        event.preventDefault();
    }

    return <>
        <section className={`reports`}>
            <SectionHeader>
                <h2>Reports</h2>
            </SectionHeader>
            <GridRow medium={gridConstants.column.THREE}>
                <GridColumn>
                    <SearchableSingleSelectField
                        {...reportForm.reportType}
                        includeDefaultOption={false}
                        label="Date Product Disseminated:"
                        options={PTS_REPORT_TYPES_LIST}
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <DateSelectField
                        dateRangeType={reportForm.dateRangeType.value}
                        endDate={reportForm.endDate.value}
                        label={`Date`}
                        name={`filterDate`}
                        onSelectDate={handleSelectDate}
                        optionsToUse={dateSelectOptionChoices.quarters}
                        startDate={reportForm.startDate.value}
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <Button name={`btnDownload`}
                            label={`Generate Report`}
                            buttonType={ButtonTypes.DOWNLOAD}
                            onClick={handleDownloadReport}
                    />
                </GridColumn>
            </GridRow>
        </section>
    </>;
};
